import Form from '@/components/form';
import FormPhoneField from '@/components/form/fields/phoneField';
import FormTextField from '@/components/form/fields/textField';
import { ModalFormWrapper } from '@/components/form/modal';
import useUserInfo from '@/providers/auth/useUserInfo';
import { Stack } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function SendRawTextMessage( { number, message, secondaryTitle }: {
	number?: string,
	message: string,
	secondaryTitle?: string
} ) {
	const { staff } = useUserInfo();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	
	return (
		<Form
			initialValues={{
				message: message,
				number : number,
			}}
			onSubmit={async ( values ) => {
				try {
					await axios.post( '/api/message', {
						company: staff?.company.id,
						number : values.number,
						message: values.message,
					} );
					enqueueSnackbar( t( 'common:text-sent' ), { variant: 'success' } );
				} catch ( e ) {
					console.log( e );
					if ( !values.number ) {
						enqueueSnackbar( t( 'common:add-cell' ), { variant: 'default' } );
						return;
					}
					enqueueSnackbar( t( 'common:text-not-sent' ), { variant: 'error' } );
					return;
				}
			}}>
			{( formik ) => (
				<ModalFormWrapper
					title={t( 'common:send-text' )}
					secondaryTitle={secondaryTitle}
					saveButtonProps={{
						disabled: !formik.values.number || !formik.values.message,
					}}
					saveButtonText='Share'>
					<Stack spacing={2}>
						<FormPhoneField
							name='number'
							placeholder={t( 'common:phone-number' )}
							onlyCountries={[ 'us', 'ca' ]}
							onChange={( value, data, event, formattedValue ) => {
								formik.setFieldValue( 'number', formattedValue );
							}}
						/>
						<FormTextField
							fullWidth
							multiline
							name='message'
							placeholder={t( 'common:type-here' )}
							variant='outlined'
							rows={5}
							inputProps={{
								maxLength: 180,
								mt       : 2,
							}}
						/>
					</Stack>
				</ModalFormWrapper>
			)}
		</Form>
	);
}
